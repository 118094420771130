<template>
 <a-modal
      :title="isNew?'创建用户':'编辑'"
      :visible="isShow"
      @ok="onOk"
      @cancel="onCancel"
      class="customermodel"
      width="500px"
      :centered="true"
    >
      <div class="editteacher-model">
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" :form="form">
          <a-form-item label="姓名">
            <a-input size="large" v-decorator="['real_name',{rules: [{ required: true, message: '姓名不能为空!' }]}]"/>
          </a-form-item>
          <a-form-item label="用户名">
            <a-input size="large" v-decorator="['name',{rules: [{ required: true, message: '用户名不能为空!' }]}]"/>
          </a-form-item>
          <a-form-item label="密码">
            <a-input size="large" v-decorator="['pwd',{rules: [{ required: true, message: '密码不能为空!' }]}]"/>
          </a-form-item>
          <a-form-item label="角色">
            <a-select size="large" v-decorator="['role',{rules: [{ required: true, message: '请选择角色!' }]}]">
              <a-select-option value="admin">
                管理员
              </a-select-option>
              <a-select-option value="teacher">
                员工
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="负责部门">
            <a-select size="large" mode="multiple" v-model="data.groups">
              <a-select-option v-for="item in classList" :key="item._id"  :value="item._id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="备注">
            <a-textarea :auto-size="{ minRows: 2}" v-model="data.desc"/>
          </a-form-item>
          <a-form-item v-if="oldAccount!=currentUser.account" label="是否禁用" style="margin:-20px 0 -8px;">
            <a-switch default-checked v-model="data.is_disable"/>
          </a-form-item>
        </a-form>
      </div>
</a-modal>
</template>
<script>
import { mapState } from 'vuex'
import apis from '@/business/apis'
export default {
  inject: ['eventBus'],
  props: {
    classList: { default: () => { return [] } }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'edit_schooluser' }),
      data: { roles: [''] },
      oldAccount: '',
      isNew: true,
      isShow: false
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool'
    ]),
    ...mapState('user', [
      'currentUser'
    ])
  },
  methods: {
    ShowModal (isNew, data) {
      this.isNew = isNew
      this.isShow = true
      this.oldAccount = data.name
      if (!isNew) {
        this.data = JSON.parse(JSON.stringify(data))
        this.data.pwd = '********'
      } else {
        this.data = { roles: [''] }
      }
      this.initForm()
    },
    onOk () {
      this.form.validateFields((err, values) => {
        if (err) return
        this.data.name = values.name
        this.data.real_name = values.real_name
        this.data.pwd = values.pwd
        this.data.roles[0] = values.role
        this.submit()
      })
    },
    async submit () {
      if (this.data.name !== this.oldAccount) {
        const res = await this.$http.post(apis.schoolAdmin.isExisted, { school_id: this.currentSchool._id, name: this.data.name })
        if (res.body.existed) {
          this.$message.info('相同用户已存在，不能重复！')
          return
        }
      }

      this.data.school_id = this.currentSchool._id
      if (this.isNew) {
        const response = await this.$http.post(apis.schoolAdmin.create, this.data)
        if (response.body.success) {
          this.$message.success('创建成功')
        }
      } else {
        if (this.data.pwd === '********') {
          delete this.data.pwd // 密码没有修改
        }
        const response = await this.$http.post(apis.schoolAdmin.update + this.data._id, this.data)
        if (response.body.success) {
          this.$message.success('修改成功')
        }
      }
      this.isShow = false
      this.$emit('confirmEdit')
    },
    onCancel () {
      this.isShow = false
    },
    initForm () {
      setTimeout(() => {
        const fields = {
          name: { value: this.data.name },
          real_name: { value: this.data.real_name },
          pwd: { value: this.data.pwd },
          role: { value: this.data.roles[0] }
        }
        this.form.setFields(fields)
      }, 0)
    }
  },
  created () {
    this.eventBus.$on('show-editschooluser-modal', this.ShowModal)
  },
  beforeDestroy () {
    this.eventBus.$off('show-editschooluser-modal', this.ShowModal)
  }
}
</script>

<template>
  <div class="teacher-manage">
    <div class="list">
      <div class="title">
        <h1>用户管理</h1>
        <div class="fun">
          <search placeholder="请输入姓名、用户名" @searchKeyChange="loadData" ref="search"></search>
          <div class="button">
            <a-button
              type="primary"
              size="large"
              @click="addSchoolAccount"
            >创建用户</a-button>
          </div>
        </div>
      </div>
      <div class="table">
        <a-table :columns="columns" :data-source="data" bordered class="custom-table" :pagination="pagination" @change="tableChange" rowKey='_id'>
          <template slot="is_disable" slot-scope="text, record">
            <span :class="{fred:record.is_disable}">{{record.is_disable ? '是' : '否'}}</span>
          </template>
          <template slot="operation" slot-scope="text, record">
            <a @click="editSchoolAccount(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="delSchoolAccount(record)">删除</a>
          </template>
        </a-table>
      </div>
    </div>
  <edit-school-user :classList='classList' @confirmEdit='loadData'></edit-school-user>
  <delete-confirm @confirmDelete='confirmDeleteFun'></delete-confirm>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import editSchoolUser from '@/components/systemManage/EditSchoolUser'
import deleteConfirm from '@/components/systemManage/DeleteConfirm'
import search from '@/components/common/Search'
import apis from '@/business/apis'
import { roleTypes } from '@/business/enums'
const columns = [
  {
    title: '姓名',
    dataIndex: 'real_name',
    width: 120
  },
  {
    title: '用户名',
    dataIndex: 'name',
    width: 140
  },
  {
    title: '角色',
    dataIndex: 'roleNames',
    width: 100
  },
  {
    title: '负责部门',
    dataIndex: 'classes'
  },
  {
    title: '备注',
    dataIndex: 'desc',
    width: 180
  },
  {
    title: '是否禁用',
    dataIndex: 'is_disable',
    width: 90,
    scopedSlots: { customRender: 'is_disable' }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    width: 120
  }
]

export default {
  inject: ['eventBus'],
  components: {
    editSchoolUser,
    deleteConfirm,
    search
  },
  data () {
    return {
      data: [],
      classList: [],
      pagination: {
        current: 1
      },
      columns
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool'
    ]),
    ...mapGetters('user', [
      'isTeacher'
    ])
  },
  methods: {
    async loadData () {
      let query = '?schoolId=' + this.currentSchool._id + '&current=' + this.pagination.current
      if (this.$refs.search && this.$refs.search.searchKey) {
        query += ('&keyword=' + this.$refs.search.searchKey)
      }
      const response = await this.$http.get(apis.schoolAdmin.list + query)
      query = '?schoolId=' + this.currentSchool._id + '&pageSize=100000'
      const classResponse = await this.$http.get(apis.studentGroup.list + query)
      this.data = response.body.list
      this.pagination = response.body.pagination
      this.classList = classResponse.body.list

      this.data.forEach(user => {
        user.classes = this.getClasses(user.groups || [])
        user.roleNames = this.getRoleNames(user.roles)
      })
    },
    getClasses (ids) {
      let result = ''
      for (let i = 0; i < ids.length && ids[i]; i++) {
        const temp = this.classList.filter(item => item._id === ids[i])
        if (!temp[0]) continue
        result = result ? result + '、' + temp[0].name : temp[0].name
      }
      return result
    },
    getRoleNames (roles) {
      let result = ''
      roles && roles.forEach((item, index) => {
        result = index === 0 ? roleTypes[item] : result + '、' + roleTypes[item]
      })
      return result
    },
    tableChange (pagination, filters, sorter) {
      this.pagination.current = pagination.current
      this.loadData()
    },
    addSchoolAccount () {
      this.eventBus.$emit('show-editschooluser-modal', true, {})
    },
    editSchoolAccount (data) {
      this.eventBus.$emit('show-editschooluser-modal', false, data)
    },
    delSchoolAccount (data) {
      this.eventBus.$emit('show-deleteconfirm-modal', data.real_name, data)
    },
    async confirmDeleteFun (data) {
      const response = await this.$http.post(apis.schoolAdmin.delete + data._id)
      if (response.body.success) {
        this.$message.success('删除成功')
        await this.loadData()
      }
    }
  },
  async created () {
    if (this.isTeacher) {
      this.$router.back()
      return
    }
    await this.loadData()
  }
}
</script>

<style lang="less">
.teacher-manage {
  .list {
    border: 1px solid #e7e7eb;
    background: #fff;
    border-radius: 6px;
    padding: 0 16px;
    > .title {
      padding-top: 16px;
      display: flex;
      align-items: center;
      h1 {
        flex: 1;
        font-size: 20px;
      }
      .fun {
        display: flex;
        align-items: center;
        .search {
          .ant-input {
            height: 44px;
          }
          .anticon {
            font-size: 18px;
          }
        }
        .button {
          display: flex;
          align-items: center;
          .ant-btn {
            width: 110px;
            margin-left: 16px;
          }
        }
      }
    }
    .table {
      margin: 16px 0;
    }
  }
}
.editteacher-model{
  .ant-form-item {
    margin-bottom: 32px;
    .ant-form-explain {
      position: absolute;
      top: 55px;
    }
  }
}
</style>
